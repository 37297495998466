<template>
  <b-form-group
    label="Event"
    label-for="event"
  >
    <v-select
      id="event"
      label="activity_name"
      :reduce="event => event.id"
      :options="eventsOptions"
      @input="onEventChange"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'

export default {
  name: 'SelectEvent',
  components: {
    vSelect,
  },
  data() {
    return {
      eventsOptions: [],
    }
  },
  created() {
    this.getEvents()
  },
  methods: {
    getEvents() {
      this.$activities.get(`/internalops/entity-upcoming-events/${this.$store.getters['mainEntity/getEntityId']}`).then(res => {
        this.eventsOptions = res.data.data
      })
    },
    onEventChange(event) {
      this.$emit('update:event', event)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
