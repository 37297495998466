import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function bookingStatus() {
  const { successfulOperationAlert } = handleAlerts()

  const approveBooking = (session, booking) => axiosInstances.activities.post('/internalops/sessions-bookings/approve', {
    session_id: session,
    attendees: booking.attendees.map(attendee => attendee.id),
  }).then(() => {
    successfulOperationAlert('Booking is approved successfully')
    booking.attendees[0].attending_status = 'approved'
  })

  const rejectBooking = (session, booking, reason) => axiosInstances.activities.post('/internalops/sessions-bookings/reject', {
    session_id: session,
    attendees: booking.attendees.map(attendee => attendee.id),
    rejectReason: reason,
  }).then(() => {
    successfulOperationAlert('Booking is rejected successfully')
    booking.attendees[0].attending_status = 'rejected'
  })

  const cancelBooking = (session, booking) => axiosInstances.activities.post('/internalops/sessions-bookings/cancel', {
    session_id: session,
    attendees: booking.attendees.map(attendee => attendee.id),
  }).then(() => {
    successfulOperationAlert('Booking is cancelled successfully')
    booking.attendees[0].attending_status = 'cancelled'
  })

  return {
    approveBooking,
    rejectBooking,
    cancelBooking,
  }
}
