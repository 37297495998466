<template>
  <validation-observer #default="{handleSubmit}">
    <b-form>
      <b-modal
        id="reject-reason-modal"
        title="Reject Reason"
        centered
        static
        @hide="resetReason"
      >
        <text-area-input
          id="reject-reason"
          name="Reason"
          rules="required"
          :text.sync="reason"
        />

        <template #modal-footer="{cancel}">
          <b-button
            @click="cancel()"
          >
            Cancel
          </b-button>

          <b-button
            variant="primary"
            @click="handleSubmit(rejectBooking)"
          >
            Confirm
          </b-button>
        </template>
      </b-modal>
    </b-form>
  </validation-observer>
</template>
<script>
import TextAreaInput from '../common/FormInputs/TextAreaInput.vue'

export default {
  name: 'RejectBookingModal',
  components: { TextAreaInput },
  props: {
    submit: { type: Function, default: () => {} },
    selectedBooking: { type: Object, default: () => {} },
  },
  data() {
    return {
      reason: '',
    }
  },
  methods: {
    rejectBooking() {
      this.submit(this.selectedBooking.session, this.selectedBooking.item, this.reason).then(() => {
        this.$bvModal.hide('reject-reason-modal')
      })
    },
    resetReason() {
      this.reason = ''
    },
  },
}
</script>
<style lang="">

</style>
